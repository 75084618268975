<template>
  <div class="content_box">
    <titleHeader :title="$route.meta.title"></titleHeader>

    

    <div v-if="userInfo.category==1" class="bind_box">
      <div class="input_box">
        <input type="text" v-model="companyName" placeholder="输入店名" />
      </div>
      <div class="input_box">
        <input type="text" v-model="customerName" placeholder="输入老板姓名" />
      </div>
      <div class="input_box">
        <input type="text" v-model="tel" placeholder="输入联系电话" />
      </div>
      <div class="input_box">
        <input type="text" v-model="companyAddress" placeholder="输入地址" />
      </div>
      <span class="btn" @click="beBealer">申请成为经销商</span>



    
    </div>
    <div v-else-if="userInfo.category==3">
        <span class="btn" @click="beBealer">申请中，待审核</span>
    </div>
    <div v-else>
      
    </div>
  </div>
</template>
<script>
import {Toast} from "vant";
import "../assets/css/loginRegister.scss";
import {getUser,beBealer} from "../utils/api";
export default {
  data() {
    return {
      code: null,
      companyName: null,
      companyAddress: null,
      tel: null,
      customerName: null, //验证码状态
      times: 60, //秒数
      registerCode: null,
      userInfo:null,
    };
  },
  mounted() {
    this.registerCode = this.$route.query.registerCode;
    this.getUser();
  },
  methods: {
    beBealer(){
      if(this.companyName && this.tel && this.companyAddress && this.customerName){
      beBealer({
        companyName:this.companyName,
        companyAddress:this.companyAddress,
        tel:this.tel,
        customerName:this.customerName,
      })
      .then((res) => {
        console.log(res);
        this.getUser();
      })
      .catch((err) => {
        console.log(err);
      });
      }
    },
    getUser(){
      getUser()
      .then((res) => {
        console.log(res);
        this.userInfo=res.data;
      })
      .catch((err) => {
        console.log(err);
      });
    },
  },
};
</script>
